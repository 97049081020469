/**
 *  @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */
import React from 'react';
import { useSelector } from 'react-redux';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import frLocale from '@fullcalendar/core/locales/fr';

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

import { testDroit } from '../../Utils/testDroit';

import * as fonctionMesCours from '../../../fonctionsRoles/mesCours';

const CalendarCustom = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    let eventGuid = 0;
    let date = new Date();
    if (date.getDay() === 0) {
        date = new Date(date.setDate(date.getDate() - 7));
    }
    function createEventId() {
        return String(eventGuid++);
    }
    const events = [];
    let header = false;
    let week = 'timeGridWeek';
    let formatJOur = { weekday: 'short' };
    let height = 'auto';

    function formatDate(jour) {
        const dateFormat = new Date(date.setDate(date.getDate() - date.getDay() + jour)).toLocaleDateString().replace(/\//g, '-').split('-').reverse();
        return dateFormat.join('-');
    }
    switch (props.type) {
        case 'agent':
            if (props.tableau.length > 0) {
                for (let elem = 0; elem < props.tableau.length; elem++) {
                    const eleve = (title) => {
                        let nomEleve = '';
                        for (let index = 0; index < props.tableau[elem].eleves.length; index++) {
                            nomEleve =
                                nomEleve +
                                ` ${props.tableau[elem].eleves[index].nomPrenomEleve ? props.tableau[elem].eleves[index].nomPrenomEleve : ''}${
                                    props.tableau[elem].eleves.length !== 1 ? ',' : ''
                                }`;
                        }
                        if (title === 1) {
                            return props.tableau[elem].nom ? props.tableau[elem].nom : '';
                        } else if (title > 1) {
                            return 'Collectif';
                        }
                        return nomEleve;
                    };
                    let ajoutEvent = {
                        id: createEventId(),
                        title: `${props.tableau[elem].libelleType}: ${eleve(props.tableau[elem].eleves.length)}`,
                        start: `${formatDate(props.tableau[elem].jour)}T${props.tableau[elem].heureDebut}`,
                        end: `${formatDate(props.tableau[elem].jour)}T${props.tableau[elem].heureFin}`,
                        extendedProps: {
                            heure: `${props.tableau[elem].heureDebut} ${props.tableau[elem].heureFin}`,
                            site: props.tableau[elem].libelleSite ? props.tableau[elem].libelleSite : '',
                            groupe: props.tableau[elem].libelleGroupe ? props.tableau[elem].libelleGroupe : '',
                            salle: props.tableau[elem].fk_salle ? props.tableau[elem].libelleSalle : '',
                            ativite: props.tableau[elem].libelleType ? props.tableau[elem].libelleType : '',
                            eleve: eleve(),
                        },
                        color: props.tableau[elem].color,
                    };
                    if (testDroit(user[usurpation].roleFonction, fonctionMesCours.Lien_liste_de_presences)) {
                        if (process.env.REACT_APP_ACCESS_MODULE.split(',').includes('presences')) {
                            ajoutEvent.url = `/fiche-presence/${props.tableau[elem].idAtelier}`;
                        }
                    }
                    events.push(ajoutEvent);
                }
            }
            break;
        case 'eleve':
            if (props.tableau.length > 0) {
                for (let index = 0; index < props.tableau.length; index++) {
                    for (let elem = 0; elem < props.tableau[index].atelier.length; elem++) {
                        const selectAtelier = props.tableau[index].atelier[elem];
                        const enseignant = () => {
                            const nomPrenom = [];
                            for (let index = 0; index < selectAtelier.absence.length; index++) {
                                nomPrenom.push(selectAtelier.absence[index].libelleEnseignant);
                            }
                            return nomPrenom;
                        };
                        const ajoutEvent = {
                            id: createEventId(),
                            title: `${selectAtelier.libelleType} \n ${selectAtelier.heureDebut.substring(
                                0,
                                selectAtelier.heureDebut.length - 3,
                            )}\n${selectAtelier.heureFin.substring(0, selectAtelier.heureDebut.length - 3)}`,
                            start: `${formatDate(selectAtelier.jour)}T${selectAtelier.heureDebut}`,
                            end: `${formatDate(selectAtelier.jour)}T${selectAtelier.heureFin}`,
                            extendedProps: {
                                heure: `${selectAtelier.heureDebut} ${selectAtelier.heureFin}`,
                                site: selectAtelier.libelleSite ? selectAtelier.libelleSite : '',
                                salle: selectAtelier.libelleSalle ? selectAtelier.libelleSalle : '',
                                enseignant: enseignant(),
                                eleve: props.tableau[index].prenomEleve,
                            },
                            color: selectAtelier.libelleType !== 'Cours annulé' ? props.tableau[index].color : '#ff0000',
                        };
                        events.push(ajoutEvent);
                    }
                }
            }
            height = '80%';
            break;
        case 'planning':
            if (props.tableau.length > 0) {
                for (let elem = 0; elem < props.tableau.length; elem++) {
                    for (let index = 0; index < props.tableau[elem].tableau.atelier.length; index++) {
                        const selectAtelier = props.tableau[elem].tableau.atelier[index];
                        if (selectAtelier.jour) {
                            const ajoutEvent = {
                                id: createEventId(),
                                title: `${!selectAtelier.libelleEnseignant ? selectAtelier.libelleType : selectAtelier.libelleEnseignant}`,
                                start: `${formatDate(selectAtelier.jour)}T${selectAtelier.heureDebut}`,
                                end: `${formatDate(selectAtelier.jour)}T${selectAtelier.heureFin}`,
                                extendedProps: {
                                    heure: `${selectAtelier.heureDebut} - ${selectAtelier.heureFin}`,
                                    salle: props.tableau[elem].tableau.libelleSalle,
                                    enseignant: selectAtelier.libelleEnseignant ? selectAtelier.libelleEnseignant : '',
                                    title: `${!selectAtelier.libelleType ? '' : selectAtelier.libelleType}`,
                                    visibilite: `${!selectAtelier.visibilite ? '' : selectAtelier.visibilite}`,
                                },
                                color: props.tableau[elem].color,
                            };
                            events.push(ajoutEvent);
                        }
                    }
                }
            }
            header = {
                left: 'timeGridWeek,timeGridDay prev,next',
                center: 'title',
                right: '',
            };
            formatJOur = { weekday: 'long' };

            break;
        default:
            break;
    }

    function tooltipEvent(mouseEnterInfo) {
        switch (props.type) {
            case 'agent':
                tippy(mouseEnterInfo.el, {
                    content: `
                Heure:${mouseEnterInfo.event.extendedProps.heure}<br/>
                Activité: ${mouseEnterInfo.event.extendedProps.ativite}<br/>
                Groupe: ${mouseEnterInfo.event.extendedProps.groupe}<br/>
                Site: ${mouseEnterInfo.event.extendedProps.site}<br/>
                Salle: ${mouseEnterInfo.event.extendedProps.salle}<br/>
                Élève: ${mouseEnterInfo.event.extendedProps.eleve}<br/>
            `,
                    placement: 'bottom',
                    allowHTML: true,
                });
                break;
            case 'eleve':
                tippy(mouseEnterInfo.el, {
                    content: `
                Heure: ${mouseEnterInfo.event.extendedProps.heure}<br/>
                Activité: ${mouseEnterInfo.event.title}<br/>
                Site: ${mouseEnterInfo.event.extendedProps.site}<br/>
                Salle: ${mouseEnterInfo.event.extendedProps.salle}<br/>
                Enseignant: ${mouseEnterInfo.event.extendedProps.enseignant}<br/>
                Élève: ${mouseEnterInfo.event.extendedProps.eleve}<br/>
            `,
                    placement: 'bottom',
                    allowHTML: true,
                });
                break;
            case 'planning':
                tippy(mouseEnterInfo.el, {
                    content: `
                Heure: ${mouseEnterInfo.event.extendedProps.heure}<br/>
                Salle: ${mouseEnterInfo.event.extendedProps.salle}<br/>
                Activité: ${mouseEnterInfo.event.extendedProps.title}<br/>
                Enseignant: ${mouseEnterInfo.event.extendedProps.enseignant}<br/>
                Visibilité: ${mouseEnterInfo.event.extendedProps.visibilite}<br/>
            `,
                    placement: 'bottom',
                    allowHTML: true,
                });
                break;
            default:
                break;
        }
    }

    if (window.matchMedia('(max-width: 576px)').matches) {
        week = 'timeGridDay';
        formatJOur = { weekday: 'long' };
        header = {
            left: 'prev,next today',
            center: '',
            right: '',
        };
        if (props.type === 'eleve') {
            height = 'auto';
        }
    }

    return (
        <div className={props.className}>
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin]}
                headerToolbar={header}
                events={events}
                locale={frLocale}
                allDaySlot={false}
                displayEventTime={false}
                initialView={week}
                slotMinTime="07:00:00"
                eventMouseEnter={tooltipEvent}
                dayHeaderFormat={formatJOur}
                contentHeight="1200px"
                height={height}
            />
        </div>
    );
};

export default CalendarCustom;
