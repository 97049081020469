/* -----------Fiche responsable----------- */

export const tableHeadCotisation = [
    { id: 'AnneeScolaireCotisation', label: 'Année scolaire' },
    { id: 'explication', label: 'Explications' },
];

export const inputGeneralPart1 = [
    { label: 'Nom', input: 'nom' },
    { label: 'Prènom', input: 'prenom' },
    { label: 'Email', input: 'email' },
    { label: 'Adresse', input: 'adresse1' },
    { label: 'Adresse suite', input: 'adresse2' },
];
export const inputGeneralPart2 = [
    { label: 'Lieu de naissance', input: 'lieuNaissance' },
    { label: 'Code postal', input: 'departementNaissance' },
];
export const inputPassword = [
    { label: 'Mot de passe', input: 'password' },
    { label: 'Confirmation mot de passe', input: 'CPassword' },
];
export const verifDataSaveResponsable = [
    'nom',
    'prenom',
    'email',
    'dateNaissance',
    'lieuNaissance',
    'fk_civilite',
    'departementNaissance',
    'adresse1',
    'codePostal',
    'ville',
    'telephoneMobile',
];
export const tableHeadCotisationDetail = [{ label: 'Montant' }, { label: 'Date' }, { label: 'Quittance' }, { label: 'Mode' }];
export const tableHead = [
    { id: 'nomPrenomEleve', label: 'Elèves' },
    { id: 'dateNaissance', label: 'Naissance' },
    { id: 'instrument', label: `Date d'inscription - Instrument` },
    { id: 'concatInfoResponsable', label: 'Contact(s)' },
];
export const acceptedRolesConsult = ['SuperAdmin', 'Administratif', 'Enseignant Resp.', 'Enseignant'];

export const tableHeadScolariteExterne = [
    { id: 'AnneesScolaireExterne', label: 'Année scolaire' },
    { id: 'etablissementExterne', label: 'Etablissement' },
    { id: 'niveauExterne', label: 'Niveau' },
    { id: '', label: 'Cours Mercredi' },
];
export const tableHeadResponsable = [
    { id: 'nomPrenomResponsable', label: 'Nom Prenom' },
    { id: 'fixeResponsable', label: 'Fixe' },
    { id: 'mobileResponsable', label: 'Mobile' },
    { id: 'roleResponsable', label: 'Rôle' },
    { id: 'emailResponsable', label: 'Email' },
];
export const tableHeadScolariteInterne = [
    { id: 'anneeScolaireInterne', label: 'Année scolaire' },

    { id: 'siteInterne', label: 'Site' },
    { id: 'offreInterne', label: 'Offre' },
    { id: 'precisionOffreInterne', label: 'Précision offre' },
    { id: 'infoFMGInterne', label: 'Précision FM-G' },
    { id: 'statutInterne', label: 'Statut' },
    { id: 'EnseignantInterne', label: 'Enseignant' },
    {
        id: 'CommentaireInscriptionConservatoire',
        label: "Commentaire à l'inscription",
    },
];
export const tableHeadAtelier = [
    { id: 'type', label: 'Type' },
    { id: 'site', label: 'Site' },
    { id: 'salle', label: 'Salle' },
    { id: 'horaire', label: 'Horaire' },
    { id: 'enseignant', label: 'Enseignant' },
];
export const input = [
    { label: 'Nom', input: 'nom', type: '' },
    { label: 'Prénom', input: 'prenom', type: '' },
    { label: 'Téléphone mobile', input: 'telephoneMobile', type: '' },
    { label: 'Date de naissance', input: 'dateNaissance', type: 'date' },
];
