/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author viaud Benjamin
 * @copyright Horizon-Pharma
 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import local from 'antd/es/date-picker/locale/fr_FR';

import { Table, Select, DatePicker } from 'antd';

import { GET } from '../../components/Utils/requete';
import formatSelect from '../../components/Utils/FormatSelect';
import { Jour } from '../../components/Utils/consts';
import RechercheTable from '../../components/Utils/rechercheTable';

const { RangePicker } = DatePicker;

const ListePresenceExcuse = (props) => {
    const [periode, setPeriode] = useState([dayjs().subtract(1, 'years').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]);
    const [dataPresences, setDataPresences] = useState([]);
    const [copieData, setCopieData] = useState([]);
    const [sites, setSites] = useState([]);
    const [selectSite, setSelectSite] = useState([]);

    async function listePresence() {
        const dateDebut = `dateDebut=${periode[0].toLocaleString('fr-FR').split(' ')[0].split('/').reverse().join('-')}`;
        const dateFin = `dateFin=${periode[1].toLocaleString('fr-FR').split(' ')[0].split('/').reverse().join('-')}`;
        let response = await GET(`/api/presences/listePresencesExcuse?${dateDebut}&${dateFin}`);
        setDataPresences(response.data);
        setCopieData(response.data);

        if (sites.length === 0) {
            const response = await GET('/api/sites/liste');
            if (!response.success) return props.notification({ message: response.message });
            if (response.success) setSites(formatSelect(response.data, 'idSite', 'libelleSite'));
        }
    }

    function jourAteliers(idJour) {
        const j = Jour.filter((e) => e.id === idJour);
        if (j.length === 1) return j[0].lib;
    }
    function filtreArray(site) {
        const tableauFiltre = [];
        setSelectSite(site);
        if (site.length !== 0)
            for (let index = 0; index < site.length; index++) {
                const element = copieData.filter((e) => +e.fk_site === +site[index]);
                console.log(element);
                tableauFiltre.push(...element);
            }
        else return setDataPresences(copieData);
        setDataPresences(tableauFiltre);
    }
    useEffect(() => {
        props.addNomPage('Déclaration des absences');
        listePresence();
    }, [periode]);
    const columns = [
        {
            render: (text, data) => (
                <div style={{ width: `${(window.innerWidth / 100) * 90}px` }}>
                    <div className="flex spaceBetween borderBottom"></div>
                    <div className="flex spaceBetween ">
                        <span className="mr5">Instrument : </span>
                        <span>{data.libelleInstrument} </span>
                    </div>
                    <div className="flex spaceBetween ">
                        <span className="mr5">Statut : </span>
                        <span>{data.libelleStatut} </span>
                    </div>
                    <div className="flex spaceBetween ">
                        <span className="mr5">Date d'inscription : </span>
                        <span>{dayjs(data.dateCreation).format('DD/MM/YYYY')} </span>
                    </div>
                    <div className="flex spaceBetween ">
                        <span className="mr5">Droit image : </span>
                        <span>{data.etatDroitImage ? 'OUI' : 'NON'} </span>
                    </div>
                </div>
            ),
            responsive: ['xs'],
        },
        {
            title: 'Élèves',
            render: (text, data) => (
                <Link className="fontSize0_9em" to={`/fiche-eleve/${data.fk_eleve}`}>
                    {data.nomPrenomEleve}
                </Link>
            ),
            width: '12rem',
            responsive: ['sm'],
        },
        {
            title: 'Site',
            render: (text, data) => <span>{data.libelleSite}</span>,
            width: '6rem',
            responsive: ['sm'],
        },
        {
            title: 'Enseignant',
            render: (text, data) => (
                <Link className="fontSize0_9em" to={`/fiche-agent/${data.fk_enseignant}`}>
                    {data.nomPrenomEnseignant}
                </Link>
            ),
            width: '8rem',
            responsive: ['sm'],
        },

        {
            title: "Date d'excuse",
            sorter: (a, b) => a.dateExcuse && b.dateExcuse && a.dateExcuse.localeCompare(b.dateExcuse),
            render: (text, data) => (data.dateExcuse ? <span>{dayjs(data.dateExcuse).format('DD/MM/YYYY')}</span> : <></>),
            width: '6rem',
            responsive: ['sm'],
        },
        {
            title: 'Jour / Heure',
            render: (text, data) => (
                <span>
                    <span className="fontSmallGrey">{jourAteliers(data.jour)}</span>
                    <br />
                    {data.heureDebut} {data.heureFin}
                </span>
            ),
            width: '8rem',
            responsive: ['sm'],
        },
        {
            title: 'Atelier',
            render: (text, data) => (
                <Link className="fontSize0_9em" to={`/fiche-atelier/${data.fk_atelier}`}>
                    {data.libelleType}
                </Link>
            ),
            width: '10rem',
            responsive: ['sm'],
        },
        {
            title: 'Demandeur',
            render: (text, data) => (
                <div>
                    {data.role && data.role === 'Agent' && (
                        <>
                            <span className="fontSmallGrey">{data.role} : </span>
                            <br />
                            <Link className="fontSize0_9em" to={`/fiche-agent/${data.agent.id}`}>
                                {data.agent.nomPrenom}
                            </Link>
                        </>
                    )}
                    {data.role && data.role === 'Responsable' && (
                        <>
                            <span className="fontSmallGrey">{data.role} :</span>
                            <br />
                            <Link className="fontSize0_9em" to={`/fiche-responsable/${data.responsable.id}`}>
                                {data.responsable.nomPrenom}
                            </Link>
                        </>
                    )}
                </div>
            ),
            width: '12rem',
            responsive: ['sm'],
        },
        {
            title: 'Commentaire',
            render: (text, data) => <span className="fontSize0_9em">{data.commentaire}</span>,
            responsive: ['sm'],
        },
    ];
    return (
        <div className="listeContainer">
            <div className="flex mt2 mb2 alignICenter">
                <div className="w50">
                    <div className=" textRight alignICenter flex mb2 xs_block xs_textLeft">
                        <label className="w20  xs_inlineBlock">Site: </label>
                        <Select onChange={(value) => filtreArray(value)} mode="multiple" className="nomargin ml30px w50" value={selectSite} options={sites} />
                    </div>
                    <div className=" textRight alignICenter flex xs_block xs_textLeft">
                        <label className="w20 xs_inlineBlock ">Periode : </label>
                        <RangePicker
                            className="w50 nomargin ml30px"
                            value={[dayjs(periode[0]), dayjs(periode[1])]}
                            format={'DD-MM-YYYY'}
                            locale={local}
                            onChange={(dates, dateStrings) => {
                                if (dateStrings[0]) setPeriode([dateStrings[0].split('-').reverse().join('-'), dateStrings[1].split('-').reverse().join('-')]);
                                else setPeriode([dayjs().subtract(1, 'years').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]);
                            }}
                            presets={[
                                { label: "Aujourd'hui", value: [dayjs(), dayjs()] },
                                { label: '7 derniers jours', value: [dayjs().subtract(7, 'days'), dayjs()] },
                                { label: '30 derniers jours', value: [dayjs().subtract(30, 'days'), dayjs()] },
                                { label: '90 derniers jours', value: [dayjs().subtract(90, 'days'), dayjs()] },
                                { label: '12 derniers mois', value: [dayjs().subtract(12, 'month'), dayjs()] },
                            ]}
                        />
                    </div>
                </div>

                <RechercheTable classNames="w50 floatRight" data={copieData} dataFiltre={(data) => setDataPresences(data)} />
            </div>

            <Table
                columns={columns}
                dataSource={dataPresences}
                pagination={{
                    showTotal: (total, range) => `${range[0]}-${range[1]} / ${total} `,
                    position: ['topRight'],
                    total: [].length,
                    defaultCurrent: 1,
                    defaultPageSize: 20,
                }}
            />
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(ListePresenceExcuse);
