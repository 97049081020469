/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FormControl, Button, Select, FormHelperText } from '@material-ui/core';

import { GET } from '../../components/Utils/requete';
import Input from '../../components/atoms/TextField';
import TelephoneInput from '../../components/organism/Input/telephone';
import DateInput from '../../components/organism/Input/date';
import valuesQuery from '../../components/Utils/valuesQuery';
import { insertInput, affichagePassword } from '../../components/Utils/password';
import recupCommune from '../../components/Utils/recupCommune';
import { rolePMT } from '../../components/Utils/consts';

import useStylesMaterielUIGlobal from '../../styles/StyleMaterielUIGlobal';

const inputGeneralPart1 = [
    { label: 'Nom', input: 'nom' },
    { label: 'Prénom', input: 'prenom' },
    { label: 'Email', input: 'email' },
    { label: 'Adresse', input: 'adresse1' },
    { label: 'Adresse', input: 'adresse2' },
];
const inputGeneralPart2 = [
    { label: 'Lieu de naissance', input: 'lieuNaissance' },
    { label: 'Code postal', input: 'departementNaissance' },
];

const FormulaireResponsable = (props) => {
    const css = useStylesMaterielUIGlobal();
    const history = useHistory();
    const dataResponsable = useSelector((state) => state.DataInscriptionTarn.responsable);
    const reloadView = useSelector((state) => state.DataInscriptionTarn.reloadView);
    const [listeCommune, setListeCommune] = useState([]);
    const [SocialCateg, setSocialCat] = useState([]);
    const [password, setPassword] = useState('');
    const [confirmationPassword, setConfirmationPassword] = useState('');
    const [errorPassword, seterrorPassword] = useState('');
    const [nonRemplisPassword, setNonRemplisPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState([]);
    const [domaine, setDomaine] = useState('');
    const verifData = [
        dataResponsable.fk_civilite,
        dataResponsable.nom,
        dataResponsable.prenom,
        dataResponsable.email,
        dataResponsable.adresse1,
        dataResponsable.codePostal,
        dataResponsable.profession,
        dataResponsable.fk_categorieSocioProfessionnelle,
        dataResponsable.dateNaissance,
        dataResponsable.lieuNaissance,
        dataResponsable.departementNaissance,
        dataResponsable.telephoneMobile,
        dataResponsable.fk_role,
    ];
    async function recupFiltre() {
        const socialCatSelect = await GET('/api/utilisateursResponsables/categorieSocioProfessionnelle');
        setSocialCat(socialCatSelect.data);
    }
    async function LoadListeCommune(codePostal) {
        if (listeCommune.length === 0) {
            const tableauCommune = await recupCommune(codePostal);
            setListeCommune(tableauCommune);
        }
    }
    function verificationPassword(event) {
        if (event.length < confirmationPassword.length) {
            setConfirmationPassword(event);
        } else {
            for (let index = 0; index < event.length; index++) {
                if (event.split('')[index] === password.split('')[index]) {
                    setConfirmationPassword(event);
                    props.dataResponsable({ password: event });
                    seterrorPassword('');
                } else {
                    setConfirmationPassword(event);
                    seterrorPassword('Les deux mot de passe doivent être identique.');
                }
            }
        }
    }
    function codePostalValue(value) {
        if (value && value.length === 5) {
            LoadListeCommune(value);
        }
        return value;
    }
    async function parametreDomaine() {
        const response = await GET(`/api/parametres/getValeur/blocageDomaine`);
        if (response.success) setDomaine(response.data.valeur);
    }

    function infoDmaine() {
        let domaineString = '';
        if (domaine.includes('|')) {
            for (let index = 0; index < domaine.split('$|').length; index++) {
                if (index === domaine.split('$|').length - 1)
                    domaineString += `${domaine.split('$|')[index].replace('\\@', '').replace('\\', '').replace('$', '')} `;
                else domaineString += `${domaine.split('$|')[index].replace('\\@', '').replace('\\', '')}, `;
            }
        } else {
            domaineString += `${domaine.replace('\\@', '').replace('\\', '').replace('$', '')} `;
        }
        return domaineString;
    }

    function champNonRemplis(emailExist) {
        const erreur = [];
        for (let index = 0; index < verifData.length; index++) {
            if (emailExist) {
                if (index === 3) {
                    erreur.push(emailExist);
                } else {
                    erreur.push('');
                }
            } else {
                if (!verifData[index]) {
                    erreur.push('information manquante');
                } else {
                    erreur.push('');
                }
            }
        }
        setErrorMessage(erreur);
    }
    async function dataIsSet() {
        const url = valuesQuery('finalisation') ? '/inscription/finalisationInscription' : '/inscription/documentResponsable';
        if (!dataResponsable.email.split('').includes('@')) {
            return champNonRemplis('Vous devez renseigner une adresse mail valide');
        }
        if (dataResponsable.email.match(domaine)) {
            return champNonRemplis('Vous devez renseigner autre adresse mail.');
        }
        if (verifData.includes(null) || verifData.includes('')) {
            champNonRemplis();
        } else if (!confirmationPassword || errorPassword !== '') {
            setNonRemplisPassword('Le mot de passe doit être rempli');
        } else {
            const response = await GET(
                `/api/utilisateursResponsables/emailExist?email=${dataResponsable.email}&dateNaissance=${dataResponsable.dateNaissance}`,
                undefined,
                {
                    redirectOnError: false,
                },
            );
            if (response.success) {
                champNonRemplis('Un compte avec cette email exite déjà.');
            } else {
                history.push(url);
            }
        }
    }
    function view() {
        return (
            <div className=" bgcfff h100 flex directionColumn p3">
                <div className="flex alignICenter w100">
                    <div className="flex  pl2 w55 radius10 directionColumn" style={{ border: `2px solid #2e76b0` }}>
                        <h3>Coordonnées du responsable</h3>
                    </div>
                </div>
                <div className="p2 ">
                    <div className="flex mt2 ">
                        <div className="w50 ">
                            <div className="flex alignICenter mb0_5">
                                <label className="w40 ">Genre</label>
                                <FormControl className={[css.w40]} error={errorMessage[0]}>
                                    <Select
                                        value={dataResponsable.fk_civilite}
                                        className={css.w100}
                                        onChange={(event) => props.dataResponsable({ fk_civilite: event.target.value })}
                                    >
                                        <option value=""></option>
                                        <option value={1}>Madame</option>
                                        <option value={2}>Monsieur</option>
                                    </Select>
                                    <FormHelperText>{errorMessage[0]}</FormHelperText>
                                </FormControl>
                            </div>
                            {inputGeneralPart1.map((elem, index) => (
                                <>
                                    {elem.input !== 'email' && (
                                        <div key={index} className={`flex alignICenter mb0_5`}>
                                            <label className="w40 ">{elem.label}</label>
                                            <Input
                                                value={dataResponsable[elem.input]}
                                                className={[css.w40, css.mt0]}
                                                onChange={(event) => {
                                                    props.dataResponsable({ [elem.input]: event.target.value });
                                                }}
                                                helperText={elem.input !== 'adresse2' ? <span style={{ color: 'red' }}>{errorMessage[index + 1]}</span> : ''}
                                            />
                                        </div>
                                    )}
                                    {elem.input === 'email' && (
                                        <div key={index} className={`flex alignICenter mb0_5`}>
                                            <label className="w40 flex directionColumn">
                                                Email
                                                <span className="fontSize0_7em ">Les adresses mail finissant par {infoDmaine()} ne sont pas accepté.</span>
                                            </label>
                                            <Input
                                                value={dataResponsable.email}
                                                className={[css.w40, css.mt0]}
                                                onChange={(event) => {
                                                    props.dataResponsable({ email: event.target.value });
                                                }}
                                                helperText={<span style={{ color: 'red' }}>{errorMessage[3]}</span>}
                                            />
                                        </div>
                                    )}
                                </>
                            ))}
                            <div className="flex alignICenter mb0_5">
                                <label className="w40 ">Code Postal</label>
                                <Input
                                    value={codePostalValue(dataResponsable.codePostal)}
                                    className={[css.w40, css.mt0]}
                                    onChange={(event) => {
                                        props.dataResponsable({ codePostal: event.target.value });
                                        LoadListeCommune(event.target.value);
                                    }}
                                    helperText={<span style={{ color: 'red' }}>{errorMessage[5]}</span>}
                                />
                            </div>
                            <div className="flex alignICenter mb0_5">
                                <label className="w40 ">Ville</label>
                                <FormControl className={[css.w40, css.mt0, css.mb8px]}>
                                    <Select
                                        native={true}
                                        value={dataResponsable.ville}
                                        onChange={(event) => props.dataResponsable({ ville: event.target.value })}
                                    >
                                        <option value={''}></option>
                                        {listeCommune.map((elem, index) => {
                                            return (
                                                <option key={index} value={elem}>
                                                    {elem}
                                                </option>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="flex mb1">
                                <label className="w40 ">Téléphone fixe</label>
                                <TelephoneInput
                                    className="textLeft "
                                    value={dataResponsable.telephoneFixe}
                                    onChange={(event) => props.dataResponsable({ telephoneFixe: event })}
                                    styleSpan="fontSize0_6em"
                                />
                            </div>
                            <div className="flex ">
                                <label className="w40 ">Téléphone mobile</label>
                                <TelephoneInput
                                    className="textLeft "
                                    value={dataResponsable.telephoneMobile}
                                    onChange={(event) => props.dataResponsable({ telephoneMobile: event })}
                                    styleSpan="fontSize0_6em"
                                    errorMessage={errorMessage[11]}
                                />
                            </div>
                        </div>
                        <div className="w50">
                            <div className="flex alignICenter mb0_5">
                                <label className="w40 ">Profession</label>
                                <Input
                                    value={dataResponsable.profession}
                                    className={[css.w40, css.mt0]}
                                    onChange={(event) => props.dataResponsable({ profession: event.target.value })}
                                    helperText={<span style={{ color: 'red' }}>{errorMessage[6]}</span>}
                                />
                            </div>
                            <div className="flex alignICenter mb0_5">
                                <label className="w40 ">Catégorie socio-professionnelle</label>
                                <FormControl className={[css.w40]} error={errorMessage[7]}>
                                    <Select
                                        value={dataResponsable.fk_categorieSocioProfessionnelle}
                                        className={css.w100}
                                        onChange={(event) => props.dataResponsable({ fk_categorieSocioProfessionnelle: event.target.value })}
                                    >
                                        <option value=""></option>
                                        {SocialCateg.map((elem, index) => (
                                            <option key={index} value={elem.idCSP}>
                                                {elem.libelle}
                                            </option>
                                        ))}
                                    </Select>
                                    <FormHelperText>{errorMessage[7]}</FormHelperText>
                                </FormControl>
                            </div>
                            <DateInput
                                classesLabel="w40 "
                                label={'Date de naissance'}
                                classeDiv="flex alignICenter mb0_5 "
                                format="dd/MM/yyyy"
                                value={dataResponsable.dateNaissance ? new Date(dataResponsable.dateNaissance.split('/').reverse().join('-')) : null}
                                className={[css.w40, css.nonargin]}
                                onChange={(event) => props.dataResponsable({ dateNaissance: event ? event.toLocaleDateString('fr-FR') : event })}
                                helperText={<span style={{ color: 'red' }}>{errorMessage[8]}</span>}
                            />
                            {inputGeneralPart2.map((elem, index) => (
                                <div key={index} className="flex alignICenter mb0_5">
                                    <label className="w40 ">{elem.label}</label>
                                    <Input
                                        value={dataResponsable[elem.input]}
                                        className={[css.w40, css.mt0]}
                                        onChange={(event) => props.dataResponsable({ [elem.input]: event.target.value })}
                                        helperText={<span style={{ color: 'red' }}>{errorMessage[index + 9]}</span>}
                                    />
                                </div>
                            ))}
                            <div className="flex alignICenter mb0_5">
                                <label className="w40 ">Rôle</label>
                                <FormControl className={[css.w40]} error={errorMessage[0]}>
                                    <Select
                                        value={dataResponsable.fk_role}
                                        className={css.w100}
                                        onChange={(event) => props.dataResponsable({ fk_role: event.target.value })}
                                    >
                                        <option value=""></option>
                                        {rolePMT.map((elem, index) => {
                                            return (
                                                <option key={index} value={elem.id}>
                                                    {elem.lib}
                                                </option>
                                            );
                                        })}
                                    </Select>
                                    <FormHelperText>{errorMessage[12]}</FormHelperText>
                                </FormControl>
                            </div>
                            <p className=" mt10" style={{ color: 'red' }}>
                                {nonRemplisPassword}
                            </p>
                            <div className="flex alignICenter mb0_5 ">
                                <label className="w40 ">Mot de passe</label>
                                <Input
                                    value={affichagePassword(password)}
                                    className={[css.w40, css.mt0]}
                                    onChange={(event) => setPassword(insertInput(event.target.value, password))}
                                />
                            </div>
                            <div className="flex alignICenter mb0_5">
                                <label className="w40 ">Confirmation mot de passe</label>
                                <Input
                                    value={affichagePassword(confirmationPassword)}
                                    className={[css.w40, css.mt0]}
                                    onChange={(event) => verificationPassword(insertInput(event.target.value, confirmationPassword))}
                                    helperText={<span style={{ color: 'red' }}>{errorPassword}</span>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex mt2">
                    <Button size="small" variant="contained" className={[css.BcBleu, css.radius10, css.cWhite, css.mb5, css.ml15]} onClick={() => dataIsSet()}>
                        Continuer l'inscription
                    </Button>
                </div>
            </div>
        );
    }
    useEffect(() => {
        setPassword(dataResponsable.password);
        setConfirmationPassword(dataResponsable.password);
    }, []);
    useEffect(() => {
        view();
    }, [reloadView]);
    useEffect(() => {
        recupFiltre();
        parametreDomaine();
    }, []);
    useEffect(() => {
        if (!dataResponsable) {
            history.push('/inscription/accueil');
        }
    }, []);
    if (!dataResponsable) {
        return <></>;
    }
    return view();
};

function mapDispatchToProps(dispatch) {
    return {
        dataResponsable(data) {
            dispatch({ type: 'dataResponsable', data: data });
        },
    };
}

export default connect(null, mapDispatchToProps)(FormulaireResponsable);
