export const Lien_profil_responsable_liste_eleve = 113;
export const Lien_profil_eleve = 114;
export const Trie_site = 115;
export const Ajout_nouvel_eleve_liste_eleve = 116;
export const Suppression_eleve = 117;
export const Envoi_email = 118;
export const Lien_profil_responsable = 120;
export const Lien_atelier = 121;
export const Lien_profil_enseignant = 122;
export const Lien_livret = 123;
export const Ajout_nouvel_élève = 124;
export const Modifier_eleve = 125;
export const Ajout_nouveau_responsable = 126;
export const Gerer_roles = 127;
export const Associer_un_responsable = 128;
export const Desassocier_responsable = 129;
export const Ajout_nouvelle_SE = 130;
export const Edition_SE = 131;
export const Supprimer_une_SE = 132;
export const Ajout_nouvelle_SI = 133;
export const Edition_SI = 134;
export const Supprimer_une_SI = 135;
export const ModificationDroitImage = 300;
export const CreatEval = 306;
export const DeleteEval = 308;
export const RemplirEval = 307;
