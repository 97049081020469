/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button, Select, Input, Modal } from 'antd';

import { DELETE, GET, POST } from '../../../components/Utils/requete';

import { testDroit } from '../../../components/Utils/testDroit';

import { DeleteEval, RemplirEval } from '../../../fonctionsRoles/eleve';
import formatSelect from '../../../components/Utils/FormatSelect';
import CustomTooltips from '../../../components/organism/Tooltip/customTooltip';
import { DeleteOutlined } from '@ant-design/icons';
import anneeEnCour from '../../../components/Utils/requeteParametre/anneeEnCour';

const { TextArea } = Input;

const Evaluation = (props) => {
    let { id } = useParams();
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const [AnneeScolaireListe, setAnneeScolaireListe] = useState([]);
    const [AnneeScolaire, setAnneeScolaire] = useState();
    const [listEvalSI, setListEvalSI] = useState([]);
    const [evalEleve, setEvalEleve] = useState();
    const [valueSemestre, setValueSemestre] = useState();
    const [open, setOpen] = useState({ open: false });

    async function recupListeAnneeScolaire() {
        const response = await GET('/api/anneesScolaire/list');
        if (response.success) setAnneeScolaireListe(formatSelect(response.data, 'idAnneeScolaire', 'libelleAnneeScolaire'));
        const annees = await anneeEnCour();
        RecupDataEval(+annees.id);
    }

    async function creatEval() {
        const response = await GET(`/api/evaluation/creatEvaluationEleve/${id}`);
        if (!response.success) return props.notification({ message: response.message });
        if (response.success) props.notification({ status: response.success, message: 'Les évaluations ont été créées' });
        RecupDataEval(AnneeScolaireListe[0].value);
    }
    async function SupprimerEval() {
        const response = await DELETE(`/api/evaluation/`, open.content);
        if (response.success) props.notification({ status: response.success, message: 'Suppression effectuée' });
        setOpen({ open: false });
        RecupDataEval(AnneeScolaireListe[0].value);
    }
    async function saveEval() {
        const data = { evaluation: evalEleve, evaluationSI: listEvalSI };
        const response = await POST(`/api/evaluation/`, data);
        if (response.success) props.notification({ status: response.success, message: 'Les évaluations ont été sauvegardées' });
    }
    async function RecupDataEval(idAnneeScolaire) {
        setAnneeScolaire(idAnneeScolaire);
        const response = await GET(`/api/evaluation/listEval/${id}?idAnneeScolaire=${idAnneeScolaire}`);
        if (!response.success) {
            setListEvalSI([]);
            setEvalEleve();
            return props.notification({ message: "Aucune évaluation n'a été créée pour cette année" });
        }
        const textSemestre = [];
        for (let index = 0; index < response.data.evaluationSI.length; index++) {
            for (let i = 0; i < response.data.evaluationSI[index].atelier.length; i++) {
                textSemestre[`${response.data.evaluationSI[index].atelier[i].fk_atelier}semestre1`] = response.data.evaluationSI[index].atelier[i].semestre1;
                textSemestre[`${response.data.evaluationSI[index].atelier[i].fk_atelier}semestre2`] = response.data.evaluationSI[index].atelier[i].semestre2;
            }
        }
        setValueSemestre(textSemestre);
        setListEvalSI(response.data.evaluationSI);
        setEvalEleve(response.data.evaluation);
    }
    useEffect(() => {
        recupListeAnneeScolaire();
    }, []);

    function disabledRemplissage(atelier) {
        const index = atelier.nomPrenomUtilisateur.findIndex((e) => e.fk_enseignant === user[usurpation].fk_id);
        if (index !== -1) return false;
        if (testDroit(user[usurpation].roleFonction, RemplirEval)) return false;
        return true;
    }
    return (
        <div>
            <Modal
                open={open.open}
                onCancel={() => setOpen({ open: false })}
                closeIcon={false}
                footer={[
                    <Button className="cbleu border1bleu mr2 xs_w90" key="back" onClick={() => SupprimerEval()}>
                        Oui
                    </Button>,
                    <Button danger key="back" onClick={() => setOpen({ open: false })}>
                        Non
                    </Button>,
                ]}
            >
                <h3>Voulez-vous supprimer cette évaluation</h3>
            </Modal>
            <div className="flex spaceBetween">
                <div className="w35 textRight alignICenter flex xs_block xs_textLeft">
                    <label className="xs_inlineBlock">Année Scolaire</label>
                    <Select className="nomargin ml30px w50" value={AnneeScolaire} onChange={(values) => RecupDataEval(values)} options={AnneeScolaireListe} />
                </div>
                <Button className="cbleu border1bleu mr2 xs_w90" onClick={() => creatEval()}>
                    Générer les évaluations pour l'année en cours
                </Button>
            </div>
            <div className="mt5">
                {listEvalSI.length !== 0 && (
                    <div className="flex spaceBetween">
                        {evalEleve && (
                            <div className="flex directionColumn">
                                <span>
                                    Semestre 1 consulté par les responsables :
                                    <span style={{ color: !evalEleve.validationLectureParentSemestre1 ? 'red' : 'green' }}>
                                        {!evalEleve.validationLectureParentSemestre1 ? ' NON' : ' OUI'}
                                    </span>
                                </span>
                                <span>
                                    Semestre 2 consulté par les responsables :
                                    <span style={{ color: !evalEleve.validationLectureParentSemestre2 ? 'red' : 'green' }}>
                                        {!evalEleve.validationLectureParentSemestre2 ? ' NON' : ' OUI'}
                                    </span>
                                </span>
                            </div>
                        )}
                        <Button className="cbleu border1bleu mr2 xs_w90" onClick={() => saveEval()}>
                            Sauvegarder
                        </Button>
                    </div>
                )}

                {listEvalSI.length !== 0 &&
                    listEvalSI.map((row, i) => (
                        <div className="mb2" key={i}>
                            {row.atelier.map((atelier, index) => (
                                <div key={index}>
                                    <hr className="w100"></hr>
                                    {!row.validationLectureParentSemestre1 &&
                                    testDroit(user[usurpation].roleFonction, DeleteEval) &&
                                    !window.matchMedia('(max-width: 576px)').matches ? (
                                        <CustomTooltips title={'Supprimer cette évaluation.'}>
                                            <DeleteOutlined
                                                className=" cRed"
                                                onClick={() =>
                                                    setOpen({
                                                        open: true,
                                                        content: {
                                                            idEvaluationAtelier: atelier.idEvaluationAtelier,
                                                            idEvaluation: evalEleve.idEvaluation,
                                                            idEvaluationSI: row.idEvaluationSI,
                                                        },
                                                    })
                                                }
                                            />
                                        </CustomTooltips>
                                    ) : null}
                                    <div className="flex alignICenter">
                                        <div className="flex directionColumn w30">
                                            <span>Atelier : {atelier.libelleType}</span>
                                            <div className="flex alignICenter mt1">
                                                <span className="mr1">Enseignant :</span>
                                                <span>
                                                    {atelier.nomPrenomUtilisateur.map((enseignant, e) => (
                                                        <span key={e}>
                                                            {enseignant.nomPrenomUtilisateur} <br />
                                                        </span>
                                                    ))}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="w35">
                                            <h3>Semestre 1</h3>
                                            <div>
                                                <TextArea
                                                    disabled={disabledRemplissage(atelier)}
                                                    value={valueSemestre[`${listEvalSI[i].atelier[index].fk_atelier}semestre1`]}
                                                    className="w90"
                                                    rows={4}
                                                    onChange={({ target }) => {
                                                        listEvalSI[i].atelier[index].semestre1 = target.value;
                                                        setValueSemestre((prevState) => ({
                                                            ...prevState,
                                                            [`${listEvalSI[i].atelier[index].fk_atelier}semestre1`]: target.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="w35">
                                            <h3>Semestre 2</h3>
                                            <div>
                                                <TextArea
                                                    disabled={disabledRemplissage(atelier)}
                                                    value={valueSemestre[`${listEvalSI[i].atelier[index].fk_atelier}semestre2`]}
                                                    className="w90"
                                                    rows={4}
                                                    onChange={({ target }) => {
                                                        listEvalSI[i].atelier[index].semestre2 = target.value;
                                                        setValueSemestre((prevState) => ({
                                                            ...prevState,
                                                            [`${listEvalSI[i].atelier[index].fk_atelier}semestre2`]: target.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
            </div>
            <hr className="w100"></hr>
            {evalEleve && (
                <>
                    <h3>Projet(s) </h3>
                    <TextArea
                        value={evalEleve.projet}
                        className="w90"
                        rows={4}
                        onChange={({ target }) =>
                            setEvalEleve((prevState) => ({
                                ...prevState,
                                projet: target.value,
                            }))
                        }
                    />
                </>
            )}
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}

export default connect(null, mapDispatchToProps)(Evaluation);
