/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { Tabs, Tab } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SchoolIcon from '@material-ui/icons/School';
import FindInPageIcon from '@material-ui/icons/FindInPage';

import TabPanel from '../../../components/organism/Onglet/TabPanel';
import GeneralEleve from './General';
import ResponsableEleve from './Responsable';
import ScolariteExterne from './ScolariteExterne';
import ScolariteInterne from './ScolariteInterne';
import AteliersEleve from './Ateliers';
import Evaluation from './Evaluation';

import useStylesMaterielUIGlobal from '../../../styles/StyleMaterielUIGlobal';
import { testDroit } from '../../../components/Utils/testDroit';
import { Acces_Eleve } from '../../../fonctionsRoles/accesURL';

const FicheEleve = (props) => {
    const css = useStylesMaterielUIGlobal();
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    if (!testDroit(user[usurpation].roleFonction, Acces_Eleve)) return window.location.replace('/role-erreur');
    return (
        <div>
            {window.matchMedia('(max-width: 1080px)').matches ? (
                <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto">
                    <Tab icon={<InfoIcon />} />
                    <Tab icon={<SupervisedUserCircleIcon />} />
                    <Tab icon={<SchoolIcon />} />
                    <Tab icon={<MusicNoteIcon />} />
                    <Tab icon={<EmojiPeopleIcon />} />
                    <Tab icon={<FindInPageIcon />} />
                </Tabs>
            ) : (
                <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
                    <Tab label={'Général'} />
                    <Tab label={'Responsable'} />
                    <Tab label={'Scolarité Education Nationale'} />
                    <Tab label={'Scolarité Conservatoire'} />
                    <Tab label={'Cours & Ateliers'} />
                    {process.env.REACT_APP_ACCESS_MODULE.split(',').includes('evaluation') && <Tab label={'Evaluation'} />}
                </Tabs>
            )}

            <TabPanel value={value} index={0} className={css.smallContainer}>
                <GeneralEleve />
            </TabPanel>
            <TabPanel value={value} index={1} className={css.smallContainer}>
                <ResponsableEleve />
            </TabPanel>
            <TabPanel value={value} index={2} className={css.smallContainer}>
                <ScolariteExterne />
            </TabPanel>
            <TabPanel value={value} index={3} className={css.smallContainer}>
                <ScolariteInterne />
            </TabPanel>
            <TabPanel value={value} index={4} className={css.smallContainer}>
                <AteliersEleve />
            </TabPanel>
            <TabPanel value={value} index={5} className={css.smallContainer}>
                <Evaluation />
            </TabPanel>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        reponseRecherche(reponse) {
            dispatch({ type: 'reponseRecherche', reponse });
        },
        removeFiltre() {
            dispatch({ type: 'removeFiltre' });
        },
        filtre(filtre) {
            dispatch({ type: 'simple', filtre });
        },
        savePathAtelier(pathAtelier) {
            dispatch({ type: 'savePathAtelier', pathAtelier });
        },
        notification(note) {
            dispatch({ type: 'notification', note });
        },
    };
}
function mapStateToProps(state) {
    return {
        Page: state.Pagination,
        recherche: state.ReponseRecherche,
        orderState: state.Order,
        orderByState: state.OrderBy,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(FicheEleve);
