/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Export des composants HTML
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FaBars } from 'react-icons/fa';

import SideBar from '../SideBar/SideBar';
import TopBar from '../TopBar/TopBar';
import NavMenu from '../navMenuEspaceResponsable/navMenu';
import SchoolIcon from '@material-ui/icons/School';
import HomeIcon from '@material-ui/icons/Home';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';

import { ContainerLayout, Center } from '../../atoms/StylesBaliseHTML';
import TextNotification from '../Notification/notification';
import { GET } from '../../Utils/requete';

import Cookies from 'js-cookie';

import accueilResponsable from '../../../assets/background_espace_responsable.jpg';
import viewResponsableGenderal from '../../../assets/background_viewResponsable_general.jpg';
import viewResponsableCotisation from '../../../assets/background_viewResponsable_cotisation.jpg';
import viewEleveScolarite from '../../../assets/background_viewEleve_scolarité.jpg';
import viewEleveProfil from '../../../assets/background_viewEleve_profil.jpg';
import viewLivret from '../../../assets/background_livret.jpg';

function Layout(props) {
    const [toggled, setToggled] = useState(false);
    const history = useHistory();
    const [children, setChildren] = useState('');
    const [image, setImage] = useState(accueilResponsable);
    const [listeEleve, setListeEleve] = useState();
    const handleToggleSidebar = (value) => {
        setToggled(value);
    };
    const cookieUser = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : { status: false };
    const user = useSelector((state) => state.Utilisateur);
    const verifUsurpation = useSelector((state) => state.Utilisateur.usurpation);
    const usurpation = user.userC;
    const url = props.location.search.split('');
    url.shift();
    const selectBackgroundImage = url.join('');
    async function recupDataEleve() {
        const response = await GET('/api/utilisateursResponsables/' + user[usurpation].fk_id + '/elevesAttached', undefined, {
            redirectOnError: false,
        });
        setListeEleve(response.data);
    }
    useEffect(() => {
        /* if('maintenance'){
            return history.push('/maintenance');
        } */
        if (Cookies.get('user')) {
            props.connexionUtilisateur(cookieUser);
            if (
                cookieUser[usurpation] &&
                cookieUser[usurpation].role === 'Responsable' &&
                process.env.REACT_APP_ENVIRONNEMENT === 'TEST' &&
                process.env.REACT_APP_ACCESS_MODULE.split(',').includes('espaceResponsable')
            ) {
                history.push('/accueilResponsable');
            }
        }
        if (cookieUser.status === false) {
            history.push('/connexion');
        }

        setChildren(props.children);
    }, []);

    useEffect(() => {
        if (cookieUser[usurpation]) {
            if (user[usurpation].role === 'Responsable') {
                recupDataEleve();
                switch (selectBackgroundImage) {
                    case 'general':
                        setImage(viewResponsableGenderal);
                        break;
                    case 'cotisation':
                        setImage(viewResponsableCotisation);
                        break;
                    case 'scolariteInterne':
                        setImage(viewEleveScolarite);
                        break;
                    case 'profil':
                        setImage(viewEleveProfil);
                        break;
                    case 'livret':
                        setImage(viewLivret);
                        break;
                    default:
                        setImage(accueilResponsable);
                        break;
                }
            }
        }
    }, [selectBackgroundImage, verifUsurpation]);

    function view() {
        if (user[usurpation] && user[usurpation].role === 'Responsable' && process.env.REACT_APP_ACCESS_MODULE.split(',').includes('espaceResponsable')) {
            const modelDropDownMenu = {
                label: 'Mon compte',
                dropDown: {
                    main: {
                        id: 'main',
                        menu: [
                            { label: 'Accueil', icone: <HomeIcon />, url: '/accueilResponsable' },
                            {
                                label: 'Général',
                                icone: <AssignmentIndIcon />,
                                url: `/responsableInformation/${user[usurpation].fk_id}?general`,
                            },
                            {
                                label: 'Cotisations',
                                icone: <CreditCardIcon />,
                                url: `/responsableCotisation/${user[usurpation].fk_id}?cotisation`,
                            },
                        ],
                    },
                    secondary: [],
                },
            };
            if (listeEleve) {
                for (let index = 0; index < listeEleve.length; index++) {
                    modelDropDownMenu.dropDown.main.menu.push({
                        label: listeEleve[index].nomPrenomEleve,
                        icone: <SchoolIcon />,
                        goToMenu: listeEleve[index].prenom,
                    });
                    if (process.env.REACT_APP_ACCESS_MODULE.split(',').includes('evaluation'))
                        modelDropDownMenu.dropDown.secondary.push({
                            id: listeEleve[index].prenom,
                            menu: [
                                {
                                    label: 'Profil',
                                    icone: <AssignmentIndIcon />,
                                    url: `/eleveInformation/${listeEleve[index].fk_eleve}?profil`,
                                },
                                {
                                    label: 'Scolarité conservatoire',
                                    icone: <LibraryMusicIcon />,
                                    url: `/eleveSI/${listeEleve[index].fk_eleve}?scolariteInterne`,
                                },
                                {
                                    label: 'Evaluation',
                                    icone: <LibraryMusicIcon />,
                                    url: `/eleveEvaluation/${listeEleve[index].fk_eleve}`,
                                },
                            ],
                        });
                    else
                        modelDropDownMenu.dropDown.secondary.push({
                            id: listeEleve[index].prenom,
                            menu: [
                                {
                                    label: 'Profil',
                                    icone: <AssignmentIndIcon />,
                                    url: `/eleveInformation/${listeEleve[index].fk_eleve}?profil`,
                                },
                                {
                                    label: 'Scolarité conservatoire',
                                    icone: <LibraryMusicIcon />,
                                    url: `/eleveSI/${listeEleve[index].fk_eleve}?scolariteInterne`,
                                },
                            ],
                        });
                }
            }

            return (
                <div style={{ height: '100%', backgroundImage: `url(${image})`, backgroundSize: 'cover' }}>
                    <NavMenu structure={modelDropDownMenu} />
                    {children}
                    <TextNotification />
                </div>
            );
        } else {
            return (
                <ContainerLayout>
                    <div className="flex directionColumn bgcf3f3f4">
                        <SideBar handleToggleSidebar={handleToggleSidebar} toggled={toggled} />
                        <Center className="flex directionColumn">
                            <div className="bgcBleu0f4c81 flex">
                                <div className="xs_flex btnToggle " onClick={() => handleToggleSidebar(true)}>
                                    <FaBars />
                                </div>
                                <TopBar nomPage={props.nomPage} />
                            </div>
                            <TextNotification />
                            <div className={`ml210px xs_ml0 ${window.matchMedia('(max-width: 576px)').matches ? '' : 'pt65px'}`}>{children}</div>
                        </Center>
                    </div>
                </ContainerLayout>
            );
        }
    }
    return view();
}

// eslint-disable-next-line react/prop-types

const mapDispatchToProps = (dispatch) => {
    return {
        connexionUtilisateur(user) {
            dispatch({ type: 'CONNEXION', user });
        },
    };
};
export default connect(null, mapDispatchToProps)(Layout);
