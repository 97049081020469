import React from 'react';

import { Input } from 'antd';

const RechercheTable = ({ data, dataFiltre, classNames }) => {
    function arraySearch(value) {
        if (data && data.length > 0) {
            const filterTable = data.filter((o) => Object.keys(o).some((k) => String(o[k]).toLowerCase().includes(value.target.value.toLowerCase())));
            return dataFiltre(filterTable);
        }
    }

    return <Input.Search className={classNames} allowClear placeholder="Recherche" onChange={arraySearch} />;
};

export default RechercheTable;
