/* eslint-disable react-hooks/exhaustive-deps */
/**
 * liste information
 *
 * @author Benjamin Viaud
 * @copyright Horizon-Pharma
 */

import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Table, Modal, Button, Tooltip } from 'antd';

import { GET } from '../../components/Utils/requete';
import Container from '../../components/atoms/Container/container';
import DateHeureFormat from '../../components/Utils/DateHeureFormat';
import { testDroit } from '../../components/Utils/testDroit';
import { Liste_des_informations, Liste_des_informations_CST, Liste_des_informations_DEVELOPPEMENT } from '../../fonctionsRoles/accesURL';

const ListeInformation = (props) => {
    const user = useSelector((state) => state.Utilisateur);
    const usurpation = user.userC;
    const [dataInformation, setDataInformation] = useState([]);
    const [open, setOpen] = useState(false);
    const [modalText, setModalText] = useState('');
    let { type } = useParams();
    let idRole = 0;

    async function recupDataInformation() {
        const response = await GET(`/api/informations/list?type=${type}`);
        if (response.success) setDataInformation(response.data);
    }

    function voirText(i) {
        const idx = dataInformation.findIndex((info) => info.idInformation === i);
        if (idx >= 0) {
            setModalText(dataInformation[idx].message);
            setOpen(true);
        }
    }

    useEffect(() => {
        recupDataInformation();
        props.addNomPage(type === 'direction' ? 'Informations de direction' : 'Information de syndicat');
        switch (type) {
            case 'direction':
                props.addNomPage('Informations de direction');
                idRole = Liste_des_informations;
                break;
            case 'syndicat':
                props.addNomPage('Informations de représentants du personnel');
                idRole = Liste_des_informations_CST;
                break;
            case 'developpement':
                props.addNomPage('Informations de développement');
                idRole = Liste_des_informations_DEVELOPPEMENT;
                break;
            default:
                break;
        }
    }, [type]);
    const columns = [
        {
            render: (text, props) => (
                <div style={{ width: `${(window.innerWidth / 100) * 90}px` }}>
                    <div className="flex spaceBetween borderBottom">
                        <span className="mr5">Date : </span>
                        <Link to={`/edition-information/${props.idInformation}?type=${type}`}>{DateHeureFormat({ date: props.date })}</Link>
                    </div>
                    <div className="flex spaceBetween borderBottom">
                        <span className="mr5">Auteur : </span>
                        <span>{props.nomPrenomUtilisateur} </span>
                    </div>
                    <div className="flex spaceBetween borderBottom">
                        <span className="mr5">Destinataires: </span>
                        <span>{props.destinataires} </span>
                    </div>
                    <div className="flex spaceBetween borderBottom">
                        <span className="mr5">Titre : </span>
                        <span>{props.titre} </span>
                    </div>
                    <div className="flex spaceBetween borderBottom">
                        <span className="mr5">Message : </span>
                        <span
                            onClick={() => {
                                voirText(props.idInformation);
                            }}
                        >
                            cliquez pour voir le message
                        </span>
                    </div>
                    <div className="flex spaceBetween ">
                        <span className="mr5">Visible : </span>
                        <span>{props.visible === 0 ? 'non' : 'oui'} </span>
                    </div>
                </div>
            ),
            responsive: ['xs'],
        },
        {
            title: 'Date',
            sorter: (a, b) => a.date.localeCompare(b.date),
            render: (text, props) => (
                <Tooltip placement="bottom" title="Éditer l'information" color={'#0f4c81'}>
                    <Link to={`/edition-information/${props.idInformation}?type=${type}`}>{DateHeureFormat({ date: props.date })}</Link>
                </Tooltip>
            ),
            responsive: ['sm'],
        },
        {
            title: 'Auteur',
            width: '12em',
            dataIndex: 'nomPrenomUtilisateur',
            sorter: (a, b) => a.nomPrenomUtilisateur.localeCompare(b.nomPrenomUtilisateur),
            responsive: ['sm'],
        },
        {
            title: 'Destinataires',
            dataIndex: 'destinataires',
            width: '20em',
            sorter: (a, b) => a.destinataires.localeCompare(b.destinataires),
            responsive: ['sm'],
        },
        {
            title: 'Titre',
            dataIndex: 'titre',
            sorter: (a, b) => a.titre.localeCompare(b.titre),
            responsive: ['sm'],
        },
        {
            title: 'Message',
            render: (text, props) => (
                <Button
                    style={{ color: '#0f4c81', borderColor: '#0f4c81' }}
                    onClick={() => {
                        voirText(props.idInformation);
                    }}
                >
                    Voir le message
                </Button>
            ),
            responsive: ['sm'],
        },
        {
            title: 'Visible',
            render: (text, props) => <>{props.visible === 0 ? 'non' : 'oui'}</>,
            responsive: ['sm'],
        },
    ];
    if (idRole !== 0 && !testDroit(user[usurpation].roleFonction, idRole)) return window.location.replace('/role-erreur');
    return (
        <div className="mr2 ml2">
            <Modal
                width={'60%'}
                open={open}
                onCancel={() => setOpen(false)}
                footer={[
                    <Button danger key="back" onClick={() => setOpen(false)}>
                        Retour
                    </Button>,
                ]}
            >
                <div className="leading100 dangerouslySetInnerHTMLCSS" dangerouslySetInnerHTML={{ __html: modalText }}></div>
            </Modal>
            <div className="mt2 flex spaceBetween">
                <h2>Messages à caractères informatifs </h2>
                <div className="flex flexEnd">
                    <Link to={`/edition-information/0?type=${type}`}>
                        <Button style={{ color: '#0f4c81', borderColor: '#0f4c81' }} onClick={() => props.removeFiltreMultiple()}>
                            Nouveau Message
                        </Button>
                    </Link>
                </div>
            </div>

            <Container>
                <Table
                    columns={columns}
                    dataSource={dataInformation}
                    pagination={
                        dataInformation.length >= 10
                            ? {
                                  showTotal: (total, range) => `${range[0]}-${range[1]} / ${total} `,
                                  position: ['topRight', 'bottomRight'],
                                  total: dataInformation?.length,
                                  defaultCurrent: 1,
                                  defaultPageSize: 10,
                              }
                            : false
                    }
                />
            </Container>
        </div>
    );
};
function mapDispatchToProps(dispatch) {
    return {
        addNomPage(nomPage) {
            dispatch({ type: 'stock', nomPage });
        },
        removeFiltreMultiple() {
            dispatch({ type: 'removeFiltreMultiple' });
        },
    };
}
export default connect(null, mapDispatchToProps)(ListeInformation);
